<template>
  <div class="Subtotal">
    <div class="subtotal-header">
      <el-date-picker
        v-model="cDate"
        type="date"
        @change="getAchievement"
        value-format="yyyy-MM-dd"
        placeholder="选择日期"
        size="small"
      >
      </el-date-picker>
      <button
        v-for="(item, index) in days"
        :key="index"
        class="button-m button1"
        style="cursor: pointer"
        @click="toDays(index, item)"
        :class="{ color: index == dyname }"
      >
        {{ item.day }}
      </button>
    </div>
    <div class="Subtotal-content">
      <div class="achievement Subtotal-left">
        <p class="title">单项劳动业绩总汇</p>
        <div id="left" style="width: 100%; height: 400px"></div>
      </div>
      <div class="achievement Subtotal-middle">
        <p class="title">套餐扣项业绩总汇</p>
        <div id="zhong" style="width: 100%; height: 400px"></div>
      </div>
      <div class="achievement Subtotal-rigth">
        <p class="title">赠送金业绩总汇</p>
        <div id="zhong1" style="width: 100%; height: 400px"></div>
      </div>
      <div class="achievement Subtotal-rigth">
        <p class="title">销售业绩总汇</p>
        <div id="zhong2" style="width: 100%; height: 400px"></div>
      </div>
      <div class="achievement Subtotal-rigth">
        <p class="title">营业额总汇</p>
        <div id="right" style="width: 100%; height: 400px"></div>
      </div>
    </div>
    <div class="main-bottom">
      <div id="main" style="width: 100%; height: 346px"></div>
    </div>
  </div>
</template>

<script>
  import {getToken} from "../../utils/auth";

  export default {
    name: "",

    data() {
      return {
        days: [
          {
            day: "今天",
            val: "1",
          },
          {
            day: "昨天",
            val: "2",
          },
        ],
        cDate: "",
        day: "",
        dyname: "0",
        value1: "",
        itemMap: {},
        itemTotal: 0,
        saleTotal: 0,
        payTotal: 0,
        itemList: [],
        payList: [],
        saleList: [],

        dxldsTotal: 0,
        tcTotal: 0,
        giveTotal: 0,
        xsTotal: 0,
        yyeTotal: 0,
      };
    },
    mounted() {
      this.drawChart();
      this.leftChart();
      this.zhongChart();
      this.zhong1Chart();
      this.zhong2Chart();
      this.rightChart();
      this.getAchievement();
    },
    methods: {
      //昨天  / 今天
      toDays(index, item) {
        this.day = item.day;
        this.dyname = index;
        this.getAchievement();
      },

      async getAchievement() {
        if (this.cDate){
          this.dyname=''
        }
        let data = {
          staDate: this.cDate,
          endDate: this.cDate,
          dateType: this.dyname,
          token: getToken(),
        };
        let res = await this.$get("/sw/getAchievement", data);
        if (res.code == 200) {
          this.$nextTick(() => {
            this.dxldsTotal = res.dxldsTotal
            this.leftChart(res.dxlds);
            this.tcTotal = res.tcTotal
            this.zhongChart(res.tcs);
            this.giveTotal = res.giveTotal
            this.zhong1Chart(res.gives);
            this.xsTotal = res.xsTotal
            this.zhong2Chart(res.xss);
            this.yyeTotal = res.yyeTotal
            this.rightChart(res.yyes);
            this.drawChart(res.kcs);
          })
        }
      },

      //饼图左
      leftChart(list) {
        let myChart = this.$echarts.init(document.getElementById("left"));
        let option = {
          title: {
            text: "总计",
            subtext: this.dxldsTotal,
            top: "43%",
            left: "49%",
            textAlign: "center",
          },
          tooltip: {
            show: false,
          },
          legend: {
            orient: "vertical",
            selectedMode: false,
            left: 10,
            data: list,
            formatter: function (name) {
              let num = "";
              list.forEach((item) => {
                //格式化图例文本，支持字符串模板和回调函数两种形式。
                if (item.name === name) {
                  num = String(item.value).replace(/(\d)(?=(?:\d{6})+$)/g, "$1.");
                  return;
                }
              });
              return name + "：" + num;
            },
          },
          series: [
            {
              name: "",
              type: "pie",
              radius: ["45%", "60%"],
              avoidLabelOverlap: false,
              silent: false, //图形是否不响应和触发鼠标事件，默认为 false，即响应和触发鼠标事件。
              clickable: false, //取消图表上的点击事件
              hoverAnimation: false,
              zlevel: 1,
              label: {
                show: false,
                position: "center",
                normal: {
                  position: "inner",
                  show: false,
                },
              },
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                },
              },
              emphasis: {
                show: false,
                label: {
                  show: false,
                  fontSize: "10",
                  fontWeight: "bold",
                },
              },
              data: list,
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },

      //饼图中
      zhongChart(list) {
        let myChart = this.$echarts.init(document.getElementById("zhong"));
        let option = {
          title: {
            text: "总计",
            subtext: this.tcTotal,
            top: "43%",
            left: "49%",
            textAlign: "center",
          },

          tooltip: {
            show: false,
            trigger: "item",
            formatter: "{b}: {c} ({d}%)",
          },

          legend: {
            orient: "vertical",
            selectedMode: false, //取消图例的点击事件
            left: 10,
            data: list,
            formatter: function (name) {
              let num = "";
              list.forEach((item) => {
                //格式化图例文本，支持字符串模板和回调函数两种形式。
                if (item.name === name) {
                  num = String(item.value).replace(/(\d)(?=(?:\d{6})+$)/g, "$1.");
                  return;
                }
              });
              return name + "：" + num;
            },
          },

          series: [
            {
              name: "",
              type: "pie",
              radius: ["45%", "60%"],
              avoidLabelOverlap: true,
              hoverAnimation: false,
              label: {
                show: false,
                position: "center",
                normal: {
                  position: "inner",
                  show: false,
                },
              },

              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                },
              },

              emphasis: {
                label: {
                  show: false,
                  fontSize: "10",
                  fontWeight: "bold",
                },
              },
              data: list,
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },

      //饼图中1
      zhong1Chart(list) {
        let myChart = this.$echarts.init(document.getElementById("zhong1"));
        let option = {
          title: {
            text: "总计",
            subtext: this.giveTotal,
            top: "43%",
            left: "49%",
            textAlign: "center",
          },
          tooltip: {
            show: false,
            trigger: "item",
            formatter: "{b}: {c} ({d}%)",
          },
          legend: {
            orient: "vertical",
            selectedMode: false, //取消图例的点击事件
            left: 10,
            data: list,
            formatter: function (name) {
              let num = "";
              list.forEach((item) => {
                //格式化图例文本，支持字符串模板和回调函数两种形式。
                if (item.name === name) {
                  num = String(item.value).replace(/(\d)(?=(?:\d{6})+$)/g, "$1.");
                  return;
                }
              });
              return name + "：" + num;
            },
          },
          series: [
            {
              name: "",
              type: "pie",
              radius: ["45%", "60%"],
              avoidLabelOverlap: true,
              hoverAnimation: false,
              label: {
                show: false,
                position: "center",
                normal: {
                  position: "inner",
                  show: false,
                },
              },
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                },
              },
              emphasis: {
                label: {
                  show: false,
                  fontSize: "10",
                  fontWeight: "bold",
                },
              },
              data: list,
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },

      //饼图中2
      zhong2Chart(list) {
        let myChart = this.$echarts.init(document.getElementById("zhong2"));
        let option = {
          title: {
            text: "总计",
            subtext: this.xsTotal,
            top: "43%",
            left: "49%",
            textAlign: "center",
          },
          tooltip: {
            show: false,
            trigger: "item",
            formatter: "{b}: {c} ({d}%)",
          },
          legend: {
            orient: "vertical",
            selectedMode: false, //取消图例的点击事件
            left: 10,
            data: list,
            formatter: function (name) {
              let num = "";
              list.forEach((item) => {
                //格式化图例文本，支持字符串模板和回调函数两种形式。
                if (item.name === name) {
                  num = String(item.value).replace(/(\d)(?=(?:\d{6})+$)/g, "$1.");
                  return;
                }
              });
              return name + "：" + num;
            },
          },
          series: [
            {
              name: "",
              type: "pie",
              radius: ["45%", "60%"],
              avoidLabelOverlap: true,
              hoverAnimation: false,
              label: {
                show: false,
                position: "center",
                normal: {
                  position: "inner",
                  show: false,
                },
              },
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                },
              },
              emphasis: {
                label: {
                  show: false,
                  fontSize: "10",
                  fontWeight: "bold",
                },
              },
              data: list,
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },

      //饼图右
      rightChart(list) {
        let myChart = this.$echarts.init(document.getElementById("right"));
        let option = {
          title: {
            text: "总计",
            subtext: this.yyeTotal,
            top: "43%",
            left: "49%",
            textAlign: "center",
          },
          tooltip: {
            show: false,
            trigger: "item",
          },
          legend: {
            orient: "vertical",
            selectedMode: false,
            left: 10,
            data: list,
            formatter: function (name) {
              let num = "";
              list.forEach((item) => {
                //格式化图例文本，支持字符串模板和回调函数两种形式。
                if (item.name === name) {
                  num = String(item.value).replace(/(\d)(?=(?:\d{6})+$)/g, "$1.");
                  return;
                }
              });
              return name + "：" + num;
            },
          },
          series: [
            {
              name: "",
              type: "pie",
              radius: ["45%", "60%"],
              avoidLabelOverlap: false,
              hoverAnimation: false,
              label: {
                show: false,
                position: "center",
                normal: {
                  position: "inner",
                  show: false,
                },
              },
              itemStyle: {
                normal: {
                  label: {
                    show: false,
                  },
                },
              },

              emphasis: {
                label: {
                  show: false,
                  fontSize: "10",
                  fontWeight: "bold",
                },
              },
              data: list,
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },

      //柱状图
      drawChart(list) {
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(document.getElementById("main"));
        // 指定图表的配置项和数据
        let option = {
          title: {
            text: "客数汇总",
          },
          tooltip: {
            trigger: "item",
            formatter: "{b}: {c}",
          },
          legend: {
            //   data: ["销量"],
            selectedMode: false,
          },
          xAxis: {
            data: [
              "总客次",
              "非指定",
              "指定",
              "男客",
              "女客",
            ],

            axisLine: {
              //x轴
              show: false,
            },
            axisTick: {
              //x轴刻度线
              show: false,
            },
            splitLine: {
              //网格线
              show: false,
            },
          },
          yAxis: {
            show: false,
          },
          series: [
            {
              name: "数量",
              type: "bar",
              barWidth: "20%",
              data: list,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    position: "top",
                    textStyle: {
                      fontSize: "12",
                      color: "#0099ff",
                    },
                  },
                  color: function (params) {
                    var colorList = [
                      "#3399ff",
                      "#0099ff",
                      "#0099ff",
                      "#0099ff",
                      "#0099ff",
                      "#0099ff",
                      "#0099ff",
                      "#0099ff",
                    ];
                    return colorList[params.dataIndex];
                  },
                },
              },
            },
          ],
        };
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .Subtotal {
    width: 100%;
    height: 100%;
    overflow:auto;
    .Subtotal-content {
      width: 100%;
      height: 450px;
      margin-top: 50px;
      padding-left: 5%;
      .achievement {
        width: 18%;
        height: 400px;
        float: left;
        border-right: #ececec solid 1px;
        border-top: #ececec solid 1px;
        border-bottom: #ececec solid 1px;
        border-left: #ececec solid 1px;
        .title {
          margin-top: 20px;
          margin-left: 20px;
          color: #404040;
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .subtotal-header {
    padding-left: 5%;
    margin-top: 20px;
    height: 32px;
  }

  .button-m {
    width: 80px;
    height: 32px;
    line-height: 32px;
    margin-left: 10px;
    background-color: #fff;
    border: #ececec solid 1px;
    border-radius: 5px;
  }

  .my-chart {
    height: 300px;
  }

  .title-u {
    height: 20px;
    line-height: 20px;
    text-align: left;
  }

  .main-bottom {
    padding-left: 5%;
    margin-top: 40px;
  }

  .color {
    background-color: rgb(68, 68, 68) !important;
    border: 0 !important;
    color: #fff;
  }

  .Subtotal-left {
    position: relative;
  }

  .left-float {
    position: absolute;
    top: 53%;
    left: 47%;
  }

  .Subtotal-middle {
    position: relative;
  }

  .zhong-float {
    position: absolute;
    top: 53%;
    left: 47%;
  }

  .Subtotal-rigth {
    position: relative;
  }

  .right-float {
    position: absolute;
    top: 53%;
    left: 47%;
  }

  // 饼图图例显示数据
  .subtotal-number {
    position: absolute;
    top: 13%;

    li {
      height: 25px;
    }
  }

  .item-number {
    left: 70%;
  }

  .sale-number {
    left: 60%;
  }

  .pay-number {
    left: 30%;
  }
</style>
